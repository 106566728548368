"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEFAULT_ROLLBACK = exports.DEFAULT_COMMIT = exports.JS_ERROR = exports.PERSIST_REHYDRATE = exports.RESET_STATE = exports.OFFLINE_BUSY = exports.OFFLINE_SEND = exports.OFFLINE_COMPLETE_RETRY = exports.OFFLINE_SCHEDULE_RETRY = exports.OFFLINE_STATUS_CHANGED = void 0;
// Literal ACTION typing allows to ensure a stricter type than string
var OFFLINE_STATUS_CHANGED = 'Offline/STATUS_CHANGED';
exports.OFFLINE_STATUS_CHANGED = OFFLINE_STATUS_CHANGED;
var OFFLINE_SCHEDULE_RETRY = 'Offline/SCHEDULE_RETRY';
exports.OFFLINE_SCHEDULE_RETRY = OFFLINE_SCHEDULE_RETRY;
var OFFLINE_COMPLETE_RETRY = 'Offline/COMPLETE_RETRY';
exports.OFFLINE_COMPLETE_RETRY = OFFLINE_COMPLETE_RETRY;
var OFFLINE_SEND = 'Offline/SEND';
exports.OFFLINE_SEND = OFFLINE_SEND;
var OFFLINE_BUSY = 'Offline/BUSY';
exports.OFFLINE_BUSY = OFFLINE_BUSY;
var RESET_STATE = 'Offline/RESET_STATE';
exports.RESET_STATE = RESET_STATE;
var PERSIST_REHYDRATE = 'persist/REHYDRATE';
exports.PERSIST_REHYDRATE = PERSIST_REHYDRATE;
var JS_ERROR = 'Offline/JS_ERROR';
exports.JS_ERROR = JS_ERROR;
var DEFAULT_COMMIT = 'Offline/DEFAULT_COMMIT';
exports.DEFAULT_COMMIT = DEFAULT_COMMIT;
var DEFAULT_ROLLBACK = 'Offline/DEFAULT_ROLLBACK';
exports.DEFAULT_ROLLBACK = DEFAULT_ROLLBACK;